<template> 
    <div class="page-content header-clear-small">
        <div class="card card-style preload-img" data-src="assets/images/ypodo_intro.jpg" data-card-height="130">
            <div class="card-center ml-3">
                <h1 class="color-white mb-0">Gérer</h1>
                <p class="color-white mt-n1 mb-0">Mes Communes préférées et notifications</p>
            </div>
            
            <div class="card-overlay bg-black opacity-10"></div>
        </div>        
		
		<div class="search-page">
            <div class="search-box search-header bg-theme card-style me-3 ms-3">
                <i class="fa fa-search" @click="zipSearchResult"></i>
                <input type="text" class="border-0" placeholder="Code postal de ma commune préférée" v-model.trim="search" @keyup.enter="zipSearchResult">
				<a href="#" @click.prevent="resetResults" v-bind:class="{ 'disabled': !search }"><i class="fa fa-times-circle color-red-dark-sdw"></i></a>
			</div>   
		</div>	
<!-- begin list result -->
        <div class="card card-style" v-if="results && (results.length > 0)">
            <div class="content">
                <h3 class="mb-4">Résultats de ma recherche</h3>
<!--

-->                
<!-- begin element repetitif-->
               <div class="splide single-slider slider-no-arrows slider-no-dots mb-4" v-for="result in results" v-bind:key="result.id">
                    <div class="splide__track">
                        <div class="splide__list">
                            <div class="splide__slide mx-3">
                                <div class="d-flex" style="position:relative">
                                    <div class="mr-4"><img :src="result.logo_url" class="me-3 rounded-circle shadow-l" style="max-width:60px"></div>
                                    <div>
                                        <h5 class="mt-1 mb-0">{{result.name}}</h5>
                                        <p class="font-10 mt-n1 color-red-dark">{{result.complement.departement}} {{result.complement.region}}</p>
                                    </div>
                                   <div class="bamboo">
									
									<a href="#" @click.prevent="addEntity(result.id)"><i class="fas fa-plus-circle font-16 color-green-dark-sdw"></i></a>
									</div>
                                </div>
                            </div>
                        </div>      
                    </div>
                </div>
				
				<!-- end element repetitif -->

            </div>
        </div>
<!-- end list results-->
<!-- begin no result -->
        <div class="card card-style" v-if="(results.length ==0 ) && (search != '') && showNoResult">
            <div class="content">
                <h3 class="mb-4">Résultats de ma recherche</h3>
				<p>La commune choisie n'est pas encore adhérente à Ypodo. Pourquoi ne pas en parler à votre Maire ?</p>
			</div>
		</div>	

<!-- end no result -->		
<!-- begin list result -->
        <div class="card card-style" v-if="entities && (entities.length > 0)">
            <div class="content">
                <h3 class="mb-4">Mes Communes préférées & notifications</h3>
                
<!-- begin element repetitif-->
               <div class="splide single-slider slider-no-arrows slider-no-dots mb-4" v-for="result in entities" v-bind:key="result.id">
                    <div class="splide__track">
                        <div class="splide__list">
                            <div class="splide__slide mx-3">
                                <div class="d-flex" style="position:relative">
                                    <div class="mr-4"><img :src="result.logo_url" class="me-3 rounded-circle shadow-l" style="max-width:60px"></div>
                                    <div>
                                        <h5 class="mt-1 mb-0"><a :href="'/commune/'+result.id">{{result.name}}</a></h5>
                                        <p class="font-10 mt-n1 mb-0 color-red-dark">{{result.complement.departement}} {{result.complement.region}}</p>
							<div class="form-check icon-check">
                            <input class="form-check-input" type="checkbox" :id="'id'+result.id" :value="result.id" v-model="checkedNames" @change="updatePreferences">
                            <label class="form-check-label" :for="'id'+result.id">Notifications</label>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-green-dark-sdw"></i>
							</div>										
                                    </div>
                                   <div class="bamboo">
									
									<a href="#" @click.prevent="removeEntity(result.id)"><i class="fas fa-times-circle font-16 color-red-dark-sdw"></i></a>
									</div>
                                </div>
                            </div>
                        </div>      
                    </div>
                </div>
				
				<!-- end element repetitif -->

            </div>
        </div>
<!-- end list results-->		
		
		
		<div class="card card-style" v-if="!loading && !islogout">
            <div class="content mb-0">
                <h2 class="mb-4">Mes préférences</h2>
                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active">Contenu de la page d'accueil</span>
                   <select class="form-control" v-model="p2">
					<option value="1">Mode automatique (à partir de mon emplacement)</option> 
					<option value="2">Uniquement mes communes préférées</option> 
					<option value="3">Mode automatique + mes communes préférées</option>
					</select>
                </div> 								
                <a href="#" @click.prevent="updatePreferences" class="btn btn-full bg-green1-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900">Enregistrer</a>
            </div>
        </div> 		
		
		<div class="card card-style mt-4" v-if="islogout">
			<h4 class="text-center text-warning pt-4 pb-4">Déconnexion</h4>
		</div>
        <Footer v-if="!loading"></Footer>
		<div class="card card-style mt-4" style="height:100vh; background-color:#f0f0f0" v-if="loading && !islogout">
            <div class="content text-center" style="margin-top:60%">
				<div class="spinner-border color-highlight" role="status"></div>
			</div>
		</div>
    </div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Compte',
  data() {
    return{
		search: '',
		results:[],
		entities:[],
		user: {},
		errors: {},
		loading: true,
		submiting: false,
		islogout: false,
		password: {},
		p1:'',
		p2:0,
		p3:'',
		showNoResult: false,
		checkedNames:[]
	}
  },
  components: {
  },
  mounted() {
  if (localStorage.getItem('isloggedin') == 1) {
    this.getAuthUser() 
	this.islogout = false
	} else {
		this.islogout = true 
	}
  },  
  methods: { 
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	resetResults(){
		this.results = []
		this.search = ''
	},
    zipSearchResult () {
				this.showNoResult = false
				this.results = []
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"zip-search"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (this.search == '') return true
				this.loading = true
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id,
					"search": this.search
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.results = response.data.data
						if (this.results.length == 0) this.showNoResult = true
						this.loading = false
					})
					.catch(error => {
						console.log(error.response.data.errors)
						this.loading = true
					})
					}
    },	
	logout() {
	this.$emit('logout', '1')
	location.href = '/'
	this.islogout = true
	},
    getAuthUser () {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"user-account"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				this.loading = true
				if (api_token) {
				
					var apiData = {
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.user = response.data.data
						this.p1 = response.data.p1
						this.p2 = response.data.p2
						this.p3 = response.data.p3
						localStorage.setItem('p1', this.p1)
						localStorage.setItem('p2', this.p2)
						localStorage.setItem('p3', this.p3)
						this.entities = response.data.entities
						this.checkedNames = this.p1.split(",");
						this.loading = false
					})
					.catch(error => {
						console.log(error.response.data.errors)
						this.loading = true
					})
					}
    },
	addEntity(entity_id) {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"add-entity"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id,
					"entity": entity_id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						//this.resetResults()
						this.getAuthUser ()
						console.log(response.data)
					})
					.catch(error => {
						console.log(error.response.data.errors)
					})
					}	
	
	},
	removeEntity(entity_id){
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"remove-entity"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id,
					"entity": entity_id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.getAuthUser ()
						console.log(response.data)
					})
					.catch(error => {
						console.log(error.response.data.errors)
					})
					}	
	},
	updatePreferences() {
				this.p1 = this.checkedNames.join(',');
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"update-preferences"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
				
					var apiData = {
					"p1" : this.p1,
					"p2" : this.p2,
					"p3" : this.p3,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						if (response.data.message =='OK') {
							this.getAuthUser()
							this.errors = {}
						}
					})
					.catch(error => {
						this.errors = error.response.data.errors
					})
					}	
	
	
	},	
  }
}
</script>
<style>
.bamboo{position:absolute;top: 30px;right: 0;}
</style>